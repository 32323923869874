import React from "react";
import classes from "./SectionHeading.module.css";
import clsx from "clsx";

const SectionHeading = ({ heading, title, textCenter, className, base950 }) => {
  return (
    <h1
      className={clsx(
        classes.heading,
        textCenter && classes.textCenter,
        className,
        base950 && classes.base950
      )}
    >
      {heading} <br /> <span className={classes.highlight}>{title}</span>
    </h1>
  );
};

export default SectionHeading;
