import React from "react";
import classes from "./Info.module.css";

import TextRevealByWord from "../../common/TextRevealing/TextRevealing";

const Info = () => {
  return (
    <div className={classes.infoContainer} id="info">
      <TextRevealByWord
        text="At Big Routing, we specialize in empowering businesses with custom software solutions that unlock the full potential of the digital landscape. Our mission is to simplify the complexities of technology, offering personalized strategies that help our clients excel in a fast-evolving market. From crypto exchanges to hosting and CDN services, we deliver end-to-end solutions that ensure your business stands out, providing the tools needed to succeed in the competitive online world."
      />
    </div>
  );
};

export default Info;
