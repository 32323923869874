import React from "react";
import classes from "./Home.module.css";
import HeroSection from "../../components/Home/HeroSection/HeroSection";
import { FaArrowDown } from "react-icons/fa6";

import { Button } from "../../components/common";
import Info from "../../components/Home/Info/Info";
import { Link } from "react-scroll";
import Services from "../../components/Home/Services/Services";
import WhyUs from "../../components/Home/WhyUs/WhyUs";
import PartnershipsAndTestimonials from "../../components/Home/PartnershipsAndTestimonials/PartnershipsAndTestimonials";
import Team from "../../components/Home/Team/Team";
import NextLevel from "../../components/Home/NextLevel/NextLevel";
const Home = () => {
  return (
    <main>
      <HeroSection />

      <Info />
      <Services />
      {/* <WhyUs /> */}
      {/* <PartnershipsAndTestimonials />
      <Team /> */}
      <NextLevel />
    </main>
  );
};

export default Home;
