import React, { useState, useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import { AiOutlineAlignRight } from "react-icons/ai";
import classes from "./Navbar.module.css";
import { logo } from "../../images";
import { Link } from "react-scroll";
import { Button } from "../common";
import clsx from "clsx";

const Navbar = () => {
  const navItems = [
    // { navItem: "about", to: "about" },
    { navItem: "Deployer", to: "https://generic-admin.bigrouting.io/deployer" },
    { navItem: "Top Up", to: "https://generic-admin.bigrouting.io/topup" },
    // { navItem: "testimonials", to: "testimonials" },
    // { navItem: "team", to: "team" },
  ];
  const [sidebar, setSidebar] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 90) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div
      className={[
        classes.wrapper,
        (isScrolled || sidebar) && classes.wrapperBg,
      ].join(" ")}
    >
      <header className={[classes.header, "container"].join(" ")}>
        <Link
          className={[classes.navItem, classes.logoContainer].join(" ")}
          to="hero"
          spy={true}
          smooth={true}
          offset={-50}
          duration={500}
          onClick={() => setSidebar((prev) => !prev)}
        >
          {" "}
          <img src={logo} alt="#" className={classes.logo} />
        </Link>

        <div
          className={[classes.navItems, sidebar && classes.sidebar].join(" ")}
        >
          {navItems.map((el, i) => (
            <React.Fragment key={i}>
              <Link
                className={classes.navItem}
                to={el.to}
                spy={true}
                smooth={true}
                offset={-120}
                duration={500}
                activeStyle={{ color: "#00BD43" }}
                onClick={() => {
                  setSidebar((prev) => !prev)
                  window.open(el.to, "_blank")
                }}
              >
                {el.navItem}
              </Link>
              {i + 1 !== navItems.length && (
                <span className={clsx(classes.navItem, classes.separator)}>
                  /
                </span>
              )}
            </React.Fragment>
          ))}
        </div>
        <div className={classes.buttonContainer}>
          <Button to="https://generic-admin.bigrouting.io/register" className={classes.button}>
            Create Project
          </Button>{" "}
          {sidebar ? (
            <IoMdClose
              className={classes.icon}
              onClick={() => setSidebar((prev) => !prev)}
            />
          ) : (
            <AiOutlineAlignRight
              className={classes.icon}
              onClick={() => setSidebar((prev) => !prev)}
            />
          )}
        </div>
      </header>
    </div>
  );
};

export default Navbar;
