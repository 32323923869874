import clsx from "clsx";
import React from "react";
import classes from "./Services.module.css";
import SectionHeading from "../../common/SectionHeading/SectionHeading";
import { BsArrowUpRight } from "react-icons/bs";

import { Heading, Text, Button } from "../../common";

const Services = () => {
  const services = [
    {
      "title": "Custom Crypto Exchange Solutions",
      "info": "Big Routing provides tailor-made crypto exchange platforms that are secure, scalable, and fully customized to meet your business needs. Our expertise ensures seamless integration with your existing infrastructure while offering innovative features.",
      "to": "#"
    },
    {
      "title": "High-Performance Hosting and CDN Services",
      "info": "Get world-class hosting and CDN solutions designed to optimize performance, ensure reliability, and scale effortlessly as your business grows. Big Routing delivers cutting-edge technologies to keep your applications running smoothly.",
      "to": "#"
    },
    {
      "title": "Bespoke Software Development",
      "info": "Whether you're looking for a complete software suite or custom tools, Big Routing provides personalized development services to create solutions that address your specific challenges, from blockchain technology to distributed systems.",
      "to": "#"
    },
    {
      "title": "Cloud Infrastructure and Deployment",
      "info": "Leverage Big Routing’s expertise in cloud computing to set up and maintain efficient, scalable, and secure cloud infrastructure. We support platforms like AWS, DigitalOcean, and Google Cloud, ensuring optimal performance for your applications.",
      "to": "#"
    },
    {
      "title": "One-on-One Consultancy",
      "info": "Big Routing offers personalized consultancy services, whether you need expert advice on cloud deployment, blockchain development, or system architecture. Get guidance tailored to your specific business needs from industry professionals.",
      "to": "#"
    }
  ]
    ;
  return (
    <section className={classes.wrapper}>
      <div className={clsx("container", classes.container)}>
        <SectionHeading heading="Our Primary" title="Services" textCenter />

        <div className={classes.services}>
          {services.map((service, i) => (
            <div
              className={classes.service}
              key={i}
              style={{ top: `${i + 1 * 100}px` }}
            >
              <Heading base950 h3>
                {service.title}
              </Heading>
              <Text xl3 base800 className={classes.info}>
                {service.info}
              </Text>
              <Button transparent className={classes.button}>
                Learn More <BsArrowUpRight />
              </Button>
            </div>
          ))}
        </div>
        <Button to="/">Contact Us</Button>
      </div>
    </section>
  );
};

export default Services;
