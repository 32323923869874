import React from "react";
import classes from "./HeroSection.module.css";
import clsx from "clsx";
import { companyLogo } from "../../../images";
import Heading from "../../common/Heading/Heading";
import { Button, Elipse, Text } from "../../common";
import { Link } from "react-scroll";
import { FaArrowDown } from "react-icons/fa6";

const HeroSection = () => {
  return (
    <>
      <section className={classes.wrapper}>
        <div className={clsx("container", classes.container)}>
          <div className={classes.headingContainer}>
            <Heading h1 className={classes.heading}>
              Crafting Solutions
            </Heading>
            <Heading h1 className={classes.secondLine} textRight>
              For Your <span className={classes.highlight}>Digital Success</span>
            </Heading>
          </div>
          <img src={"https://i.ibb.co/52SDBv4/word.png"} alt="#" className={classes.logo} />
          <div className={classes.infoContainer}>
            <Text xl3 className={classes.info}>
              Custom software solutions, strategic implementation, and seamless integration—empowering your business for success in the digital world.
            </Text>
            <div className={classes.buttonContainer}>
              <Button >Contact us</Button>
              <Button transparent>@bigrouting</Button>
            </div>
          </div>{" "}
          <Elipse className={classes.elipse1} />
          <Elipse className={classes.elipse2} />
        </div>{" "}
        <Link
          to="info"
          spy={true}
          smooth={true}
          offset={50}
          duration={1000}
          className={classes.scroll}
        >
          Scroll <FaArrowDown />
        </Link>
      </section>
    </>
  );
};

export default HeroSection;
