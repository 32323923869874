import React from "react";
import classes from "./NextLevel.module.css";
import { Button, Heading, Text } from "../../common";
import { companyLogo } from "../../../images";
import clsx from "clsx";

const NextLevel = () => {
  const socials = [
    // { name: "Linkedin", to: "#" },
    { name: "X (Twitter)", to: "#" },
    { name: "Linktr", to: "#" },
  ];
  return (
    <section className={classes.wrapper}>
      <div className={clsx(classes.container, "container")}>
        <div className={classes.infoWrapper}>
          <div className={classes.infoContainer}>
            <Heading h3>Ready to take your project to the next level?</Heading>
            <Text xl className={classes.info}>
              Contact Big Routing Agency today and let’s start building your
              crypto success story.
            </Text>
            <div className={classes.buttonContainer}>
              <Button >Contact us</Button>
              <Button transparent>@bigrouting</Button>
            </div>
          </div>

          <img src={"https://i.ibb.co/52SDBv4/word.png"} alt="#" className={classes.companyLogo} />
        </div>
        <div className={classes.bottomBar}>
          <Text lg className={classes.copyRight}>
            © 2024. Big Routing
          </Text>
          <div className={classes.socialContainer}>
            {socials.map((el, i) => (
              <a href={el.to} key={i} className={classes.socialLink}>
                {el.name}
              </a>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default NextLevel;
